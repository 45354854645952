<template>
    <div>
        <div>
            <h1 style="color:#000;">注册微信视频号</h1>
        </div>
        <div class="content">
            <div class="left">
                <h2 style="color:#000;">填写店铺基本信息，开通视频号小店</h2><br>
                <span>用进行视频号直播的微信进行扫码，填写店铺营业执照、法人身份证等信息、完成视频号小店开通。</span>
                <span @click="download" class="link">了解详情</span>
            </div>
            <div class="right">
                <button class="header_btn" @click="open">去开通</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
            }
        },
        methods: {
            download(){
                console.log('下载了 了解详情')
            },
            open(){
                console.log('点击了去开通按钮')
            },
        },
    }
</script>

<style lang="scss" scoped>
.content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}
.header_btn{
    width: 70px;
    height: 32px;
    background-color: #409eff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.link{
    color: #409eff;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
}
</style>